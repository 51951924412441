import React from 'react';
import style from './PolicyInfoSmall.module.css'
import utilities from '../../services/util.service';

const PolicyInfoSmall  = ({ policyInfo }) => {
  return <><div className={style.policyInfoSmall}>
<h2>Policy Information </h2>
<div className={style.policyInfoRow}>
    <div className={style.infoTitle}>Policy #:</div> 
    <div className={style.infoDetail}>{policyInfo?.policy_number}</div>
</div>

<div className={style.policyInfoRow}>
    <div className={style.infoTitle}>Status:</div> 
    <div className={style.infoDetail}>{policyInfo?.status_label}</div>
</div>
<div className={style.policyInfoRow}>
    <div className={style.infoTitle}>Policy Type:</div> 
    <div className={style.infoDetail}>{policyInfo?.policy_type}</div>
</div>
<div className={style.policyInfoRow}>
    <div className={style.infoTitle}>Payment Method:</div> 
    <div className={style.infoDetail}>{listToRows(policyInfo?.payment_method)}</div>
</div>
<div className={style.policyInfoRow}>
    <div className={style.infoTitle}>Paid To:</div> 
    <div className={style.infoDetail}>{utilities.simpleDate(policyInfo?.paid_to,"")}</div>
</div>
<div className={style.policyInfoRow}>
    <div className={style.infoTitle}>Next Bill Due:</div> 
    <div className={style.infoDetail}>{utilities.simpleDate(policyInfo?.next_bill_due, "N/A")}</div>
</div>
<div className={style.policyInfoRow}>
    <div className={style.infoTitle}>Amount Due:</div> 
    <div className={style.infoDetail}> {policyInfo?.amount_due == null ? "N/A" : '$' + utilities.formatDollar(policyInfo?.amount_due)}</div>
</div>
  </div>
  <div className={style.uploadHolder}> <a className={style.upload} target="_blank" rel="noreferrer" href="https://upload.silacins.com ">Upload Documents</a> </div>
         

  
  </>;
};

// keeps the type leading consistent regardless of amount of items without having extra leading at end
const listToRows = (listOfStr) => {
    return (listOfStr) ? listOfStr.map( (v,i) => {
        let s = {'textAlign': 'right'}
        s = (i === 0) ? s : {...s, 'marginTop': '.5em' }
        return <div style={s}>{v}</div>
    }  ) : '';
}

export default PolicyInfoSmall