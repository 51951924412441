import { Button } from '@mui/material';
import React, {useState, useContext, useEffect} from 'react';
import AuthService from '../../services/auth.service';
import { StyledModal, StyledModalBody } from '../StyledModal';
import { CountDown } from "../CountDown";
import ActivityDetector from './ActivityDetector';
import PolicyNumberContext from '../../contexts/PolicyNumber/PolicyNumberContext';
import {useNavigate} from "react-router-dom";
import style from './AutoLogOutModal.module.css'

let logoutTimer;
let countInterval;

const AutoLogOutModal = (props) => {
  const navigate = useNavigate();

  const popupIntervalInt = parseInt(process.env.REACT_APP_LOGOUT_POPUP_TIMER || window.LOGOUT_POPUP_TIMER)
  const logoutIntervalInt = parseInt(process.env.REACT_APP_LOGOUT_TIMER || window.LOGOUT_TIMER)
  const popupInterval = isNaN(popupIntervalInt) ? 720000 : popupIntervalInt
  const logoutInterval = isNaN(logoutIntervalInt) ? 30000 : logoutIntervalInt
  
  // testing times
  //const popupInterval = 600000 // 10 minutes
  //const logoutInterval = 30000 // 30 seconds
  //const popupInterval = 10000 // 10 seconds
  //const logoutInterval = 30000 // 30 seconds

  const activityEvents = [
    'click',
    'keydown',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'mousemove',
  ];


  /* set state */
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const {isLoggedIn, setIsLoggedIn} = useContext(PolicyNumberContext)
  let [counter, setCounter] = useState(0);



  const signOut = () => {
    setIsLoggedIn(false)

    const token = localStorage.getItem("user")
    // trying to prevent any addtional redirects if a logout even funtion didnt get cleared for whatever reason
    if (!(token === null || token?.length === 0)) {
      // logout and redirect
      const url = (AuthService.getJwtValueByKey('isHomeOffice')) ? '/home-office-login' : '/login'
      AuthService.logout();
      navigate(url);
    }
    clearTimeout(logoutTimer)
    logoutTimer = null
  }


  const startLogout = () => {
    setModalIsOpen(true)
    logoutTimer = setTimeout(signOut, logoutInterval);
  }


  const closeModal = () => {
    setModalIsOpen(false)
    clearTimeout(logoutTimer)
    logoutTimer = null
  }

  const startCount = () => {
    stopCount()
    countInterval = setInterval(() => {
      setCounter((s) => --s);
    }, 1000);
  };

  const stopCount = () => {
    clearInterval(countInterval);
    countInterval = null
  };

  useEffect(() => {
    if (modalIsOpen) {
      setCounter(logoutInterval / 1000);
      startCount();
    } else {
      stopCount();
    }
  }, [modalIsOpen]);

  useEffect(() => {
    if (counter <= 0) {
      stopCount();
    }
  }, [counter]);


  return (
    <>
      <ActivityDetector 
        activityEvents={activityEvents} 
        isActive={isLoggedIn} 
        timeout={popupInterval} 
        signOut={startLogout} 
        signoutTimerKey={1}
      />
      <StyledModal
        open={modalIsOpen}
        className={style['pop-box']}
      > 
        <StyledModalBody>
          <div className={style['content-text']}>
            Are you still there?
          </div>
          <div className={style['sub-text']}>
            Your Session is about to expire. For your security, sessions end after ten minutes of inactivity.
          </div>
          <div className={style['confirmation']}>
            <Button onClick={signOut} variant="contained" type="submit"  color="secondary">Sign Out</Button>
            <Button onClick={closeModal} variant="contained" type="submit"  color="primary">Stay Signed In  <CountDown active={true} initialValue={logoutInterval} counter={counter} /></Button>
          </div>
        </StyledModalBody>
      </StyledModal>
    </>
  )
};
export default AutoLogOutModal