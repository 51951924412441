import React, {useState} from 'react';
import style from './ForgotPassword.module.css'
import logo from './logo.png';
import { Button } from '@mui/material';
import AuthService from "../../services/auth.service";



const ForgotPassword  = () => {
    const [inputs, setInputs] = useState({
        email: ''
      });
      const [isSubmitting] = useState(false);
      const [IsSent, setIsSent] = useState(false);
      const handleChange = (event) => {
        event.persist();
        setInputs(values => ({ ...values, [event.target.name]: event.target.value }));
      };
    
      const handleSubmit = (event) => {
        if (event) event.preventDefault();
        AuthService.resetPasswordRequest({email: inputs.email}).then(
          () => {
            setIsSent(true);
          },
          (error) => {
           alert('Something went wrong, please try again!')
          }
        );
      };



  return <div className={style.body}>
  <div className={style.topRow}>
<div className={style.logoHolder}>
<img className={style.logo} alt="Silac Insurance Logo" src={logo}/>
</div>
<div className={style.portalLogo}>
<div className={style.logoTopLine}>Life & Health</div>
<div className={style.logoBottomLine}>Client Portal</div>
</div>
</div>
<div className={style.signinRow}>
<div className={style.signInBox}>
<div className={style.signinHeader}>Forget Your Password?</div>
<p className={style.resetDetails}>
Your password will be emailed to the email address you provided at registration.
</p>
<form className={style.signinForm} onSubmit={handleSubmit}>
  <input value={inputs.email || ''} onChange={handleChange} placeholder="Enter email" name="email" className={style.userName}/> <br/>
  <Button disabled={isSubmitting || inputs.email === ''} type="submit" fullWidth className={style.loginButton} variant="contained" color="primary">Email me my password</Button><br/>
{IsSent ? <div className={style.checkEmail}> We've sent you an email with instructions to reset your password. </div> : ""}
  <div className={style.forgotPassword}>
  
    <a href="/login" className={style.backToLogin}>Back</a>
  </div>
</form>

</div>
</div>



 </div>;
};
export default ForgotPassword