import React from 'react';
import { Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import style from './HomeOffice.module.css'


const blueIconColor = '#44abdd'

const PolicyNotFound = () => {
  return (
    <Grid container >
      <Grid item xs={12} sm={12} md={9} lg={9} >
        <div className={style.noPolicy}>
          <div>
            <SearchIcon style={{ color: blueIconColor, fontSize: '2.5em', float: 'left' }} alt="No policy loaded" />
            <div>
              No policy loaded
              <div>
                Please enter policy # above to view specific policy details
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default PolicyNotFound