import React from 'react';
import style from './AccountSettingsDetails.module.css'
import { TextField } from '@mui/material';


const AddressFormHomeOffice = ({contactInfo, handleChange}) => {
    return (
      <div className={style.formBlock}>
        <h3 className={style.h3}>Home</h3>
        <ul className={style.addressList}>
        { contactInfo.map( (person, id) => (
          <li>
            <h4>
                <div>{ (person?.first_name + ' ' + person?.last_name) || ''} &nbsp;</div>
                <div>&nbsp;</div>
            </h4>  
            <div className={style.formRow}>
              <TextField className={style.address} id={"Address_"+id} label="Address" value={person?.address_line1 || ''} name={"Address_"+id} variant="outlined" />
            </div>
            <div className={style.formRow}>
              <TextField className={style.formControl} id={"city_"+id} value={person?.city || ''} name={"city_"+id} label="City" variant="outlined" />
              <TextField className={style.formControl} id={"state_"+id} value={person?.state || ''} name={"state_"+id} label="State" variant="outlined" />
              <TextField className={style.formControl} id={"zip_"+id} value={person?.zip || ''} name={"zip_"+id} label="Zip Code" variant="outlined" />
            </div>
            <div className={style.formRow}>
              <TextField className={style.formControl} id={"phone_"+id} value={person?.phone || ''} name={"phone_"+id} label="Phone" variant="outlined" />
              <TextField className={style.formControl} id={"email_"+id} value={person?.login_email || ''} name={"login_email_"+id} label="Email" variant="outlined" />
            </div>
          </li>
        ))}
        </ul>
      </div>
    )
  }

export default AddressFormHomeOffice