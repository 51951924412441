import React, { useEffect, useState, useContext } from 'react';
import PolicyNumberContext from '../../contexts/PolicyNumber/PolicyNumberContext';
import { useNavigate } from "react-router-dom";
import style from './PolicyInformationDetails.module.css'
import { Grid } from '@mui/material';
import PolicyInfoSmall from '../PolicyInfoSmall/PolicyInfoSmall';
import dataService from '../../services/data.service';
import AuthService from '../../services/auth.service';
import utilities from '../../services/util.service';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import PolicySearchInput from '../HomeOffice/PolicySearchInput'
import PolicyNotFound from '../HomeOffice/PolicyNotFound';



const PolicyInformationDetails = () => {
  const [policyInfo, setPolicyInfo] = useState({ address: {}, insured_persons: [], payment_schedule: {} });
  const [policyList, setPolicyList] = useState([]);
  const { policyNumber, setPolicyNumber } = useContext(PolicyNumberContext)
  const [loading, setLoading] = useState(false);
  const [benefits, setBenefits] = useState([]);
  const [pending, setPending] = useState([]);
  const [resolved, setResolved] = useState([]);
  const [isHomeOffice, setHomeOffice] = useState(false);
  const [userName, setUserName] = useState({ firstName: '', lastName: '' })
  const [formInfo, setFormInfo] = useState({});
  const [policySearchError, setPolicySearchError] = useState(false);


  const getPdf = (claimNumber) => {
    dataService.getClaimPDF(policyNumber, claimNumber).then(
      (response) => {
        let blob = new Blob([response.data], { type: 'application/pdf' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Claim_' + claimNumber + '.pdf';
        link.click()
      });
  }


  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPolicyData() {
      setHomeOffice(AuthService.getJwtValueByKey('isHomeOffice'))
      setUserName({ firstName: AuthService.getJwtValueByKey('firstName'), lastName: AuthService.getJwtValueByKey('lastName') })
      setLoading(true);

      if (!AuthService.getJwtValueByKey('isHomeOffice')) {
        dataService.getPolicyInfo().then(
          (response) => {
            if (response.data.length === 0) {              
              AuthService.logout();
              navigate('/nopolicies');
            }
            setPolicyInfo(response.data[0]);
            setPolicyList(response.data);
            setPolicyNumber(response.data[0]?.policy_number);
          },
          (error) => {
            //console.log(error);
            AuthService.logout();
          }
        );
      } else {
        if (policyNumber && !formInfo?.policyNumber) {
          setFormInfo(values => ({ ...values, 'policyNumber': policyNumber }))
        }
        setLoading(false);
      }
    }
    fetchPolicyData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (policyNumber) {

        setLoading(true);

        dataService.getAllPolicyInfo(policyNumber).then(
          (response) => {
            //really displays everything
            setPolicyInfo(response[0]?.data); //Insurer Information and Policy Information sections
            setBenefits(response[4]?.data);
            setPending(response[2]?.data);
            setResolved(response[1]?.data);
            setLoading(false);
          },
          (error) => {
            //console.log(error);
            AuthService.logout();
          }
        );
      }
    }
    fetchData();
  }, [policyNumber]
  );

  const verifyPolicyExists = (polNum) => {
    if (polNum === policyNumber) { return false }

    setLoading(true);

    if (!polNum) {
      clearData()
      return false;
    }

    dataService.verifyPolicy(polNum).then(
      (response) => {
        setPolicyNumber(polNum);
        setPolicySearchError(false);
      },
      (error) => {
        //console.log(error);

        if (error?.response?.status === 404) {
          setPolicySearchError(true);
          setLoading(false);
        }

        clearData()
      }
    );
  }

  const clearData = () => {
    setPolicyNumber('')
    setPolicyInfo({ address: {}, insured_persons: [], payment_schedule: {} });
    setBenefits();
    setPending();
    setResolved();
    setLoading(false);
  }

  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value

    if (name === 'policyNumber') {
      setPolicySearchError(false);
      value = value.replace(/\D/, '')
    }

    event.persist();
    setFormInfo(values => ({ ...values, [name]: value }));
  };

  const handleKeyPress = (event) => {
    if (event?.key === 'Enter') {
      verifyPolicyExists(event.target.value)
    }
  }

  const submitPolicySearch = (polNum) => {
    verifyPolicyExists(formInfo.policyNumber)
  }

  return <div className={style.pageContainer}>

    <div>
      {/* <div className={style.ad}>
          <img className={style.adImg} alt="Enroll in direct deposit today" src={ad}/>
      </div> */}
      {/* Top Row Grid */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>

          <div className={style.welcomeHolder}>
            <div className={style.welcome}><span className={style.Hello}>Hello,</span> {userName?.firstName}</div>
            {!isHomeOffice &&
              <div className={style.policyholder} >
                Valued Policyholder Since <span className={style.bold}>{utilities.getYear(policyInfo?.effective_date)}</span>
              </div>
            }
          </div>

        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <FormControl variant="outlined" className={style.formControl}>

            {(!isHomeOffice) &&
              <>
                <InputLabel id="demo-simple-select-outlined-label">Policy #</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={policyNumber}
                  onChange={(e) => { setPolicyNumber(e.target.value) }}
                  label="Policy"
                >
                  {policyList?.map(policy => (
                    <MenuItem key={policy.policy_number} value={policy.policy_number}>
                      Policy #: {policy.policy_number}
                    </MenuItem>
                  ))}
                </Select>
              </>
            }

            {(isHomeOffice) &&
              <PolicySearchInput
                handleChange={handleChange}
                handleKeyPress={handleKeyPress}
                formInfo={formInfo}
                submitPolicySearch={submitPolicySearch}
                policySearchError={policySearchError}
              />
            }
          </FormControl>


        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {/* <Button  fullWidth className={style.loginButton} onClick={getPdf} variant="contained" color="primary">Download My Policy</Button> */}
        </Grid>
      </Grid>

      <br /><br />
      {loading === true && <div className={style.loader}><CircularProgress /></div>}

      {(policyNumber && loading !== true) &&
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Grid container justify="space-between" alignItems="stretch" spacing={0} className={style.cardcontainer}>
              <Grid item xs={12} sm={12} md={6} lg={6} className={style.card} >
                <h2>Insurer Information</h2>
                <Grid container spacing={3}>
                  <Grid xs={6} item>
                    <h4>Insured:</h4>

                    {policyInfo?.insured_persons?.map(person => (
                      <div key={person} className={style.insuredName}>{person}</div>
                    ))}
                  </Grid>
                  <Grid xs={6} item>
                    <h4>Address:</h4>
                    <div className={style.addressLine1}>{policyInfo?.address?.address_line_1}</div>
                    <div className={style.addressLine2}>{policyInfo?.address?.address_line_2}</div>
                    <div className={style.addressLine2}>{policyInfo?.address?.address_line_3}</div>
                    <div className={style.addressLine2}>{policyInfo?.address?.address_line_4}</div>
                  </Grid>
                  <Grid item xs={6}>
                    <h4>Telephone</h4>
                    <div className={style.telephone}>{utilities.formatPhoneNumber(policyInfo?.phone)}</div>
                  </Grid>
                  <Grid item xs={6}>
                    <h4>Effective Date</h4>
                    <div className={style.effectiveDate}>
                      {policyInfo?.effective_date}
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} className={style.benefitsCard}>
                <h2>Benefits Summary</h2>

                {benefits?.map(person => (
                  <div className={style.benefitDetail} key={person}>{person}</div>
                ))}
              </Grid>
            </Grid>
            <br /><br />
            <h2 >Payment Schedule</h2>
            <div className={style.paymentSchedule}>
              <div className={policyInfo?.payment_schedule?.frequency === "Annual" ? style.activeScheduleItem : style.scheduleItem}>
                <h3>Annual</h3>
                <div className={style.paymentAmount}>${utilities.formatDollar(policyInfo?.payment_schedule?.annual)}</div>
              </div>

              <div className={style.scheduleItem}>
                <h3>Semi-Annual</h3>
                <div className={style.paymentAmount}>${utilities.formatDollar(policyInfo?.payment_schedule?.semi_annual)}</div>
              </div>

              <div className={policyInfo?.payment_schedule?.frequency === "Quarterly" ? style.activeScheduleItem : style.scheduleItem}>
                <h3>Quarterly</h3>
                <div className={style.paymentAmount}>${utilities.formatDollar(policyInfo?.payment_schedule?.quarterly)}</div>
              </div>

              <div className={policyInfo?.payment_schedule?.frequency === "Monthly" ? style.activeScheduleItem : style.scheduleItem}>
                <h3>Monthly</h3>
                <div className={style.paymentAmount}>
                  {policyInfo?.payment_schedule?.monthly === 0 ? "N/A" : "$" + utilities.formatDollar(policyInfo?.payment_schedule?.monthly)}
                </div>
              </div>

              <div className={policyInfo?.payment_schedule?.frequency === "BankDraft" ? style.activeScheduleItem : style.scheduleItem}>
                <h3>Monthly Bank Draft</h3>
                <div className={style.paymentAmount}>${policyInfo?.payment_schedule?.monthly_bank_draft}</div>
              </div>
            </div>

            <br />
            <br />

            <div className={style.confidentialsection}>
              <br />
              <h3>Confidential Claims Summary*</h3>
              <span className={style.claimsSummary}>For information regarding claims received by us more than three months ago, please contact Benefits at 1-888-352-5124</span>
            </div>

            <br />
            <br />

            <div>
              <h2 >Pending Claims</h2>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Claimant</TableCell>
                      <TableCell align="right">Received</TableCell>
                      <TableCell align="right">Date of Service</TableCell>
                      <TableCell align="right">Claim #</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pending?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.claimant}
                        </TableCell>
                        <TableCell align="right">{utilities.simpleDate(row.received)}</TableCell>
                        <TableCell align="right">{utilities.simpleDate(row.incurred)}</TableCell>
                        <TableCell align="right">{row.claim_number}</TableCell>
                      </TableRow>
                    ))}
                    {pending?.length < 1 ? <TableRow><td className={style.blankState}>No Pending Claims</td></TableRow> : <tr></tr>}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>


            <div>
              <br />
              <br />
              <h2 >Resolved Claims for Policy #{policyInfo.policy_number}</h2>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Claimant</TableCell>
                      <TableCell align="right">Claim #</TableCell>
                      <TableCell align="right">Received Date</TableCell>
                      <TableCell align="right">Paid Date</TableCell>
                      <TableCell align="right">Date of Service </TableCell>
                      {/* <TableCell align="right">Details </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resolved?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.claimant}
                        </TableCell>
                        <TableCell align="right">{row.claim_number}</TableCell>
                        <TableCell align="right">{utilities.simpleDate(row.received)}</TableCell>
                        <TableCell align="right">{utilities.simpleDate(row.paid_date)}</TableCell>
                        <TableCell align="right">{utilities.simpleDate(row.incurred)}</TableCell>
                        <TableCell align="right"><div className={style.downloadClaim} onClick={() => getPdf(row.claim_number)}>DOWNLOAD</div></TableCell>
                      </TableRow>
                    ))}
                    {resolved?.length < 1 ? <TableRow><td className={style.blankState}>No Resolved Claims</td></TableRow> : <tr></tr>}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
          {/* Policy Info Side Bar */}

          <Grid item xs={12} lg={3} sm={12} md={3}>
            <PolicyInfoSmall policyInfo={policyInfo} />
          </Grid>
        </Grid>
      }

      {!policyNumber &&
        <PolicyNotFound />
      }

    </div>
  </div>;
};
export default PolicyInformationDetails