import React, {useState, useEffect, useContext} from 'react';
import PolicyNumberContext from '../../contexts/PolicyNumber/PolicyNumberContext';
import AddressFormHomeOffice from  './AddressFormHomeOffice'
import style from './AccountSettingsDetails.module.css'
import {IconButton, Snackbar, TextField, InputLabel, MenuItem, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, FormControl, Select } from '@mui/material';
import dataService from '../../services/data.service';
import AuthService from '../../services/auth.service';
import UtilityService from "../../services/util.service";
import PolicySearchInput from '../HomeOffice/PolicySearchInput'
import PolicyNotFound from '../HomeOffice/PolicyNotFound';


const AccountSettingsDetails  = () => {
  
  const [contactInfo, setContactInfo] = useState({});
  const [securityInfo, setSecurityInfo] =useState({question: '', answer: ''});
  const [loading, setLoading] = useState(false);
  const [passwordChange, setPasswordChange] = useState({old: "", new: "", confirm: ""})
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('test')
  const [isHomeOffice, setHomeOffice] = useState(false);
  const [formInfo ,setFormInfo] = useState({});
  const [policySearchError, setPolicySearchError] = useState(false);
  const {policyNumber, setPolicyNumber} = useContext(PolicyNumberContext)

  const handleChange = (event) => {
    if (event.target.name === "zip" && event.target.value.length > 0){
      if (event.target.value.search(/^[0-9-]+$/) === -1) return
      if (event.target.value.length - event.target.value.replaceAll("-","").length > 1) return
    }
    event.persist();
    setContactInfo(values => ({ ...values, [event.target.name]: event.target.value }));
  };

  const handlePasswordChange = (event) => {
    event.persist();
    setPasswordChange(values => ({ ...values, [event.target.name]: event.target.value }));
  };
  const handleSecurityChange = (event) => {
    event.persist();
    setSecurityInfo(values => ({ ...values, [event.target.name]: event.target.value }));
  };
  
  useEffect(() => {
    setHomeOffice(AuthService.getJwtValueByKey('isHomeOffice'))


    

    async function fetchData() {
      if(!AuthService.getJwtValueByKey('isHomeOffice')){
        

        setLoading(true);
        dataService.getPeronalInfo().then(
            (response) => {
            
              setContactInfo(response.data);
              setLoading(false);
            },
          (error) => {
            //console.log(error)
          }
        );
      } else if (AuthService.getJwtValueByKey('isHomeOffice') && policyNumber) {
        if(!formInfo?.policyNumber){
          setFormInfo(values => ({ ...values, 'policyNumber': policyNumber }))
        }

        setLoading(true);
        dataService.getPeronalInfoByPolicy(policyNumber).then(
            (response) => {
              setContactInfo(response.data);
              setLoading(false);
            },
          (error) => {
            //console.log(error)
            
            if(error?.response?.status === 404){
              clearData()
              setPolicySearchError(true);
              setLoading(false);
            }
          }
        );
      }
    }
    fetchData();
  }, [policyNumber]); 
  
  useEffect(() => {
    async function fetchSecurityData() {
      if(!AuthService.getJwtValueByKey('isHomeOffice')){
        setLoading(true);
        dataService.getSecurityQuestion().then(
            (response) => {
              setSecurityInfo(response.data);
              setLoading(false);
            },
          (error) => {
            //console.log(error)
          }
        );
      }
    }
    fetchSecurityData();
  }, []); 
  

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const validateZipCode = (zipCode)=>{
    let blnValid = false
    if (zipCode){
      let dashPosition = zipCode.indexOf('-'),
      zipCodeLength = zipCode.length
      if ((dashPosition === -1 && zipCodeLength === 9) || (dashPosition === 5 && zipCodeLength === 10)) blnValid = true
    }
    else blnValid = true
    return blnValid
  }
  const updateData = (e) => {  
    e.preventDefault();
    if (validateZipCode(contactInfo.zip)) {
      dataService.updatePersonalInfo(contactInfo).then(() => {
        setMessage("Info Updated");
        setOpen(true);
      },
      (error) => {
        setMessage('Something went wrong');
        setOpen(true);
      });
    }
    else{
      setMessage('Please enter a valid Zip Code')
      setOpen(true)
    }
 }
 const updateSecurityData = (e) => {
  e.preventDefault();

dataService.updateSecurityQuestion(securityInfo).then(
  () => {
    setMessage("Info Updated");
    setOpen(true);
  },
  (error) => {
    setMessage('Something went wrong');
    setOpen(true);

  }
);
 }

 const changePassword = (e) => {
  e.preventDefault();
  if(passwordChange.old.length > 0 && passwordChange.new === passwordChange.confirm){
    dataService.changePassword(passwordChange.old, passwordChange.new).then(
      () => {
        setMessage("Password Updated");
        setOpen(true);
      },
      (error) => {
        if(error.response.data.detail.startsWith("[")){
          const errors = JSON.parse(error.response.data.detail)
          const message = errors.map(function(error) {
              return error;
          }).join(" ");
          setMessage(message);
        }
        else if(error.response.data.detail === "Invalid Old Password")
        {
          setMessage("Invalid Old Password");
        }
        else{
          setMessage("Something went wrong");
        }
        setOpen(true);
      }
    );
  }
 
 }

 const clearData = () => {
  setPolicyNumber('')
  setContactInfo([])
  setSecurityInfo({question: '', answer: ''})
}

 const verifyPolicyExists = (polNum) => {
  if(polNum === policyNumber) { return false }

  setLoading(true);

  if(!polNum) {
    clearData()
    return false;
  }

  setPolicyNumber(polNum);
}

const handlePolicyChange = (event) => {
  let name = event.target.name
  let value = event.target.value

  if( name === 'policyNumber') {
    setPolicySearchError(false);
    value = value.replace(/\D/, '')
  }
  
  event.persist();
  setFormInfo(values => ({ ...values, [name]: value }));
};

 const handleKeyPress = (event) => {
    if(event?.key === 'Enter') {
      verifyPolicyExists(event.target.value)
    }
  }

  const submitPolicySearch = (polNum) => {
    verifyPolicyExists(formInfo.policyNumber)
  }
    
    
    return <div className={style.pageContainer}>
       <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

      

      
      
        {/* <div className={style.ad}>
          <img className={style.adImg} alt="Enroll in direct deposit today" src={ad}/>
        </div> */}
        <Grid container spacing={3} >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className={style.welcomeHolder}>
              <div className={style.welcome}><span className={style.Hello}>Account</span> Settings</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} >
            <FormControl variant="outlined" className={style.policySearchFormControl}>
              { (isHomeOffice) &&
                <PolicySearchInput 
                  handleChange={handlePolicyChange} 
                  handleKeyPress={handleKeyPress} 
                  formInfo={formInfo} 
                  submitPolicySearch={submitPolicySearch} 
                  policySearchError={policySearchError} 
                />
              }
            </FormControl>
          </Grid>
        </Grid>
      
        <br/><br/>

      { loading === true && <div className={style.loader}><CircularProgress /></div> }
      
      { ( (!isHomeOffice || (isHomeOffice && policyNumber)) && loading !== true) &&   
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            { !isHomeOffice &&
              <form onSubmit={updateData}>
                <div className={style.formBlock}>
                  <h3 className={style.h3}>Home</h3>
                  <div className={style.formRow}>
                    <TextField className={style.address} id="address" label="Address"  onChange={handleChange} name="address_line1" value={contactInfo.address_line1 || ''} variant="outlined" inputProps={{ maxLength:35 }} />
                  </div>
                  <div className={style.formRow}>
                    <TextField className={style.formControl} id="city" onChange={handleChange} value={contactInfo.city || ''} name="city" label="City" variant="outlined" inputProps={{ maxLength:25 }} />
                    <TextField className={style.formControl} id="state" onChange={handleChange} value={contactInfo.state || ''} name="state" label="State" variant="outlined" inputProps={{ maxLength:2 }} />
                    <TextField className={style.formControl} id="zip" onChange={handleChange} value={contactInfo.zip || ''} name="zip" label="Zip Code" variant="outlined" inputProps={{ maxLength:10 }} />
                  </div>
                  <div className={style.formRow}>
                    <TextField className={style.formControl} id="phone" onChange={handleChange} value={contactInfo.phone || ''} name="phone" label="Phone" variant="outlined" inputProps={{ maxLength: 10 }} />
                    <TextField className={style.formControl} id="email" onChange={handleChange} value={contactInfo.login_email || ''} name="login_email" label="Email" variant="outlined" inputProps={{ maxLength:76 }} />
                  </div>
                </div>

                { !isHomeOffice && 
                  <Button  className={style.updateinfo} variant="contained" type="submit"  color="primary">Update My Information</Button>
                }

              </form>
            }

            { isHomeOffice &&
              <AddressFormHomeOffice contactInfo={contactInfo} handleChange={handleChange} />
            }

            { !isHomeOffice && 
              <form onSubmit={changePassword}>
                <div className={style.formBlock}>
                  <h3 className={style.h3}>Change Password</h3>
                  <div className={style.formRow}>
                    <TextField className={style.formControl} name="old" onChange={handlePasswordChange} type="password" value={passwordChange.old} id="old-password" label="Enter Old Password" variant="outlined" />
                    <TextField className={style.formControl} name="new" onChange={handlePasswordChange} type="password" value={passwordChange.new} id="new-password" label="Enter New Password" variant="outlined" />
                    <TextField className={style.formControl} name="confirm" onChange={handlePasswordChange}  type="password" value={passwordChange.confirm} id="confirm-password" label="Confirm New Password" variant="outlined" />
                    {passwordChange.old !== "" ? <div className={style.validation}></div> : "" }
                    {passwordChange.new !== "" ? <div className={style.validation}>{UtilityService.validatePassword(passwordChange.new)}</div> : "" }
                    {passwordChange.new !== passwordChange.confirm && passwordChange.confirm !== "" ? <div className={style.validation}>Passwords must match</div>:""}
                    {passwordChange.old === passwordChange.confirm && passwordChange.confirm !== "" ? <div className={style.validation}>New and old passwords should be different</div>:""}
                  </div>
                </div>
                <Button disabled={passwordChange.old.length === 0 || passwordChange.new !== passwordChange.confirm || UtilityService.validatePassword(passwordChange.new).length > 0 || passwordChange.old === passwordChange.new}  className={style.updateinfo} variant="contained" type="submit"  color="primary">Reset My Password</Button>
              </form>
            }

            { !isHomeOffice && 
            <form  onSubmit={updateSecurityData}>
              <div className={style.formBlock}>
                <h3 className={style.h3}>Change My Security Question</h3>

                <div className={style.formRow}>
                  <FormControl variant="outlined" className={style.dropDown}>
                    <InputLabel id="demo-simple-select-outlined-label">Security Question</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={securityInfo.question || ""}
                        name="question"
                        onChange={handleSecurityChange}
                        label="Security Question"
                      >
                      <MenuItem value={"What street did you grow up on?"}>What street did you grow up on?</MenuItem>
                      <MenuItem value={"What city were you born in?"}>What city were you born in?</MenuItem>
                      <MenuItem value={"What was the color of your first car?"}>What was the color of your first car?</MenuItem>
                      <MenuItem value={"What is your favorite color?"}>What is your favorite color?</MenuItem>
                      <MenuItem value={"What was your childhood best friend's name?"}>What was your childhood best friend's name?</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField className={style.securityAnswer}  onChange={handleSecurityChange} id="securityQuestion" name="answer" label="Security Question Answer" value={securityInfo.answer || ''} variant="outlined"  inputProps={{ maxLength:50 }} />
                </div>
              </div>

              <Button disabled={securityInfo.answer === ""}  className={style.updateinfo} variant="contained" type="submit"  color="primary">Update My Question</Button>
            </form>
          }


          </Grid>     
          
          {/* Policy Info Side Bar */}
          <Grid item xs={12} sm={3}>
          {/* <PolicyInfoSmall/> */}
          </Grid>
        </Grid>
      }

      { (isHomeOffice && !policyNumber) && 
        <PolicyNotFound />
      }
  </div>
  };
export default AccountSettingsDetails
     
