import React, { useState } from 'react';
import style from './CreateAccount.module.css'
import logo from './logo.png'
import { Button, MenuItem, FormControl, Select } from '@mui/material';
import AuthService from "../../services/auth.service";
import UtilityService from "../../services/util.service";

const CreateAccount = () => {
  const [inputs, setInputs] = useState({
    email: '',
    social: '',
    password: '',
    confirmpassword: '',
    pollicynum: ''
  });

  const [message, setMessage] = useState("");
  const [securityInfo, setSecurityInfo] = useState({ question: 'Select Security Question', answer: '' });
  const [loading, setLoading] = useState(false);
  const [attemptSubmit, setAttemptSubmit] = useState(false);
  const [emailValidationText, setEmailValidationText] = useState("");
  const [ssnValidationText, setSSNValidationText] = useState("");
  const [policyValidationText, setPolicyValidationText] = useState("");
  const [passwordValidationText, setPasswordValidationText] = useState("");

  const handleSecurityChange = (event) => {
    // event.persist();
    setSecurityInfo(values => ({ ...values, [event.target.name]: event.target.value }));
  };

  const handleChange = (event) => {
    event.persist();
    setInputs(values => ({ ...values, [event.target.name]: event.target.value }));
  };

  const handleSocialChange = (event) => {
    event.persist();
    let number = event.target.value;
    number.replace(/-/g, "");


    //111-11-1111
    if (number.length === 3 && inputs.social.length !== 4) {
      number = number + "-";
    }
    if (number.length === 6 && inputs.social.length !== 7) {

      number = number + "-";
    }
    setInputs(values => ({ ...values, [event.target.name]: number }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    setEmailValidationText("");
    setSSNValidationText("")
    setPolicyValidationText("")
    setPasswordValidationText("")

    if (inputs.password !== inputs.confirmpassword || securityInfo.question === "Select Security Question"
      || securityInfo.answer === "" || inputs.pollicynum === "") {
      setAttemptSubmit(true);
      return;
    }

    setAttemptSubmit(false);
    setLoading(true);

    let cleansocial = inputs.social.replace(/-/g, "");
    // policy_number, email, password, security_question, security_answer
    AuthService.create(cleansocial, inputs.pollicynum, inputs.email, inputs.password, securityInfo.question, securityInfo.answer).then(
      () => {
        window.location.href = '/success';
      },
      (error) => {
        console.log(error);
        if (error.response !== undefined && error.response.status === 422) {
          if (error.response.data.detail === "Invalid Policy Number or Social Security Number.") {
            setPolicyValidationText("Policy Number Not Associated With This Account")
          } else if (error.response.data.detail === "You already have created a login.") {
            setSSNValidationText("This social security number is already in use")
          } else if (error.response.data.detail === "You must choose a unique email address.") {
            setEmailValidationText("An account already exists with this email address")
          } else if (error.response.data.detail === "Only Short and Long Term Care policies can be managed in the portal.") {
            setPolicyValidationText("Only Short and Long Term Care policies can be managed in the portal")
          }
          else if (error.response.data.detail.startsWith("[")) {
            const errors = JSON.parse(error.response.data.detail)
            const message = errors.map(function (error) {
              return error;
            }).join(" ");
            setPasswordValidationText(message);
          }
          else {
            setMessage("Something went wrong");
          }
        } else {
          setMessage("Something went wrong");
        }
        setLoading(false);
      }
    );
  };

  return <div className={style.body}>
    <div className={style.topRow}>
      <div className={style.logoHolder}>
        <img className={style.logo} alt="Silac Insurance Logo" src={logo} />
      </div>
      <div className={style.portalLogo}>
        <div className={style.logoTopLine}>Life & Health</div>
        <div className={style.logoBottomLine}>Client Portal</div>
      </div>
    </div>
    <div className={style.signinRow}>
      <div className={style.signInBox}>
        <div className={style.signinHeader}>Create Account</div>
        <form className={style.signinForm} onSubmit={handleSubmit} >
          <input value={inputs.email || ''} onChange={handleChange} placeholder="Email*" name="email" className={style.userName} /> <br />
          {inputs.email && !UtilityService.validateEmail(inputs.email) ? <div className={style.validation}>Please enter valid email address</div> : ""}
          {emailValidationText.length > 0 ? <div className={style.validation}>{emailValidationText}</div> : ""}
          <input value={inputs.social || ''} onChange={handleSocialChange} placeholder="Social Security Number *" name="social" className={style.userName} /> <br />
          {(inputs.social && !UtilityService.is_socialSecurity_Number(inputs.social)) || (inputs.social === "" && attemptSubmit) ? <div className={style.validation}>Please enter valid social security number</div> : ""}
          {ssnValidationText.length > 0 ? <div className={style.validation}>{ssnValidationText}</div> : ""}

          <input value={inputs.pollicynum || ''} onChange={handleChange} placeholder="Policy Number *" name="pollicynum" className={style.userName} /> <br />
          {/* { inputs.pollicynum ? <div className={style.validation}>Please enter a valid policy number</div> : ""}  */}
          {inputs.pollicynum === "" && attemptSubmit ? <div className={style.validation}>Please enter a valid policy number</div> : ""}
          {policyValidationText.length > 0 ? <div className={style.validation}>{policyValidationText}</div> : ""}

          <FormControl variant="outlined" className={style.dropDown}>
            {/* <InputLabel id="demo-simple-select-outlined-label">Security Question</InputLabel> */}
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={securityInfo.question || ""}
              name="question"
              onChange={handleSecurityChange}
              label="Security Question"
            >
              <MenuItem value={'Select Security Question'} disabled  >Select Security Question</MenuItem>
              <MenuItem value={"What street did you grow up on?"}>What street did you grow up on?</MenuItem>
              <MenuItem value={"What city were you born in?"}>What city were you born in?</MenuItem>
              <MenuItem value={"What was the color of your first car?"}>What was the color of your first car?</MenuItem>
              <MenuItem value={"What is your favorite color?"}>What is your favorite color?</MenuItem>
              <MenuItem value={"What was your childhood best friend's name?"}>What was your childhood best friend's name?</MenuItem>
            </Select>
          </FormControl>
          {securityInfo.question === "Select Security Question" && attemptSubmit ? <div className={style.validation}>Please select a security question</div> : ""}


          <input value={securityInfo.answer || ''} onChange={handleSecurityChange} placeholder="Security Question Answer" name="answer" className={style.userName} /> <br />
          {securityInfo.answer === "" && attemptSubmit ? <div className={style.validation}>Please enter valid security answer</div> : ""}


          <input value={inputs.password || ''} onChange={handleChange} placeholder="Password" name="password" type="password" className={style.password} />
          {inputs.password !== "" ? <div className={style.validation}>{UtilityService.validatePassword(inputs.password)}</div> : ""}
          {passwordValidationText.length > 0 ? <div className={style.validation}>{passwordValidationText}</div> : ""}
          <h3 className={style.message}>Passwords must contain at least 8 characters and at least 1 number, 1 lower case, 1 upper case and 1 special character.</h3>
          <input value={inputs.confirmpassword || ''} onChange={handleChange} placeholder="Confirm Password" name="confirmpassword" type="password" className={style.confirmPassword} /><br />
          {inputs.password !== inputs.confirmpassword && (inputs.confirmpassword !== "" || attemptSubmit) ? <div className={style.validation}>Passwords must match</div> : ""}
          <Button disabled={loading || inputs.email === '' || inputs.password === '' || UtilityService.validatePassword(inputs.password).length > 0 || inputs.password !== inputs.confirmpassword} type="submit" fullWidth className={style.loginButton} variant="contained" color="primary">Create Account</Button><br />
          <div className={style.error}>{message}</div>
          <div className={style.forgotPassword}>
            <div className={style.alreadyHave}>Already have an account?</div>
            <a href="/login" className={style.passwordReset}>Login</a>
          </div>
        </form>

        <div className={style.havingTrouble}>HAVING TROUBLE CREATING AN ACCOUNT? <br></br> CONTACT SUPPORT: 888-352-5124</div>
        <br /> <br />

      </div>
    </div>
  </div>;
};
export default CreateAccount