import React from 'react';
import styles from './StyledModal.module.css'
import { Backdrop, Modal } from '@mui/material';

const StyledModal = (props) => {
  const {className, style} = props

  return (
    <Modal
      {...props}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400
      }}
    >
      <div className={`${styles['modal']}  ${className?className:''}`}  style={style}>
        <div className={`${styles['modal-content']}  ${styles['modal-flex-content']}`}>
          {props?.children}
        </div>
      </div>
    </Modal>
  )
};
export default StyledModal