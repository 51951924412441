import React from 'react';
import {Grid} from '@mui/material';
import LeftNav from '../components/LeftNav/LeftNav';
import ClaimsHistoryDetails from '../components/ClaimsHistoryDetails/ClaimsHistoryDetails';

const ClaimsHistory  = () => {
  return (
    <Grid container spacing={3}>
  <Grid item sm={2}>
  <LeftNav activePage="claimhistory"/>
  </Grid>
  <Grid item sm={10}>
  <ClaimsHistoryDetails/>
  </Grid>
    </Grid>);
};
export default ClaimsHistory