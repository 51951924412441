import React, { useState } from 'react';
import style from './Login.module.css'
import { Button } from '@mui/material';
import logo from './logo.png'
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";

const Login = ({ success }) => {
  const [title, setTitle] = useState("Sign In");
  const [inputs, setInputs] = useState({
    username: '',
    password: ''
  });

  const [message, setMessage] = useState("");
  if (success) {
    setTitle("Success");
  }
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    event.persist();
    setInputs(values => ({ ...values, [event.target.name]: event.target.value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    AuthService.login(inputs.username, inputs.password).then(
      () => {
        window.location.href = '/';
      },
      () => {
        const resMessage = "Username or Password is Invalid"
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  return <div className={style.body}>
    <div className={style.topRow}>
      <div className={style.logoHolder}>
        <img className={style.logo} alt="Silac Insurance Logo" src={logo} />
      </div>
      <div className={style.portalLogo}>
        <div className={style.logoTopLine}>Life & Health</div>
        <div className={style.logoBottomLine}>Client Portal</div>
      </div>
    </div>
    <div className={style.signinRow}>
      <div className={style.signInBox}>
        <div className={style.signinHeader}>{title}</div>
        <form className={style.signinForm} onSubmit={handleSubmit} >
          <input value={inputs.username || ''} onChange={handleChange} placeholder="Enter Username" name="username" className={style.userName} /> <br />
          <input value={inputs.password || ''} onChange={handleChange} placeholder="Enter Password" name="password" type="password" className={style.password} /><br />
          <Button disabled={loading || inputs.username === '' || inputs.password === ''} type="submit" fullWidth className={style.loginButton} variant="contained" color="primary">Login</Button><br />
          <div className={style.error}>{message}</div>
          <div className={style.forgotPassword}>
            <div className={style.slimheader}>Forgot your password?</div>  <br />
            <Link to="/forgot" className={style.passwordReset}>Click Here for Password Reset</Link>
            <br />  <br />
            <div className={style.slimheader}>Don't have an account?</div>  <br />

            <div className={style.createAccountButton}>
              <Link to="/create" className={style.passwordReset}>Create An Account</Link>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>;
};
export default Login