import React from "react";

import { Grid } from "@mui/material" 
import LeftNav from "../components/LeftNav/LeftNav";
import AccountSettingsDetails from "../components/AccountSettingsDetails/AccountSettingsDetails";
import utilService from "../services/util.service";
const AccountSettings = () => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={2}>
        <LeftNav activePage="account" />
      </Grid>
      <Grid item sm={10}>
        <p style={{ textAlign: "center" }}>{utilService.getHeaderMessage()}</p>
        <AccountSettingsDetails />
      </Grid>
    </Grid>
  );
};
export default AccountSettings;
