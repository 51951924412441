import React from "react";
import styles from "./countdown.module.css";


const CountDown = ({ active, initialValue, counter }) => {


  return (
    <>
        <div className={styles.countDownContainer}>
          
          <svg className={styles.countDownSVG} viewBox="-50 -50 100 100 ">
            <mask>
              <circle r="50" cx="0" cy="0" />
            </mask>
            <circle
              r="45"
              cx="0"
              cy="0"
              className={styles.countDownCircle}
              style={{
                animationPlayState: "running",
                animationIterationCount: "1",
                animationTimingFunction: "linear",
                animationDuration: `${initialValue / 1000}s`,
                animationFillMode: "forwards"
              }}
            ></circle>
          </svg>
          <div className={styles.countDownNumber}>{counter}</div>
        </div>
    </>
  );
};

export default CountDown;
