import React, {useState} from 'react';
import PolicyNumberContext from './PolicyNumberContext'

export const PolicyNumberProvider = ({children}) => {
  const [policyNumber, setPolicyNumber] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const contextValue = {
    setPolicyNumber,
    policyNumber,
    setIsLoggedIn,
    isLoggedIn
  }

  return (
    <PolicyNumberContext.Provider value={ contextValue } >
      {children}
    </PolicyNumberContext.Provider>
  );
}

export default PolicyNumberProvider;