import React from "react";
import { Grid } from "@mui/material";
import LeftNav from "../components/LeftNav/LeftNav";
import PolicyInformationDetails from "../components/PolicyInformationDetails/PolicyInformationDetails";
import AutoLogOutModal from "../components/AutoLogOutModal/AutoLogOutModal";
import utilService from "../services/util.service";

const Home = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={2}>
          <LeftNav activePage="policyinformation" />
        </Grid>
        <Grid item sm={10}>
          <p style={{ textAlign: 'center' }}>{utilService.getHeaderMessage()}</p>
          <PolicyInformationDetails />
        </Grid>
      </Grid>
      <AutoLogOutModal />
    </>
  );
};
export default Home;
