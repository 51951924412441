import React from 'react';
import { Grid } from '@mui/material';
import LeftNav from '../components/LeftNav/LeftNav';
import PendingClaimsDetails from '../components/PendingClaimsDetails/PendingClaimsDetails'

const PendingClaims = () => {
  return (<Grid container spacing={3}>
    <Grid item sm={2}>
      <LeftNav activePage="pendingclaims" />
    </Grid>
    <Grid item sm={10}>
      <PendingClaimsDetails />
    </Grid>
  </Grid>);;
};
export default PendingClaims