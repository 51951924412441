import style from './NoPolicies.module.css'
import logo from './logo.png'

const NoPolicies  = () => {
  return <div className={style.body}>
    <div className={style.topRow}>
<div className={style.logoHolder}>
  <img className={style.logo} alt="Silac Insurance Logo" src={logo}/>
</div>
<div className={style.portalLogo}>
  <div className={style.logoTopLine}>Life & Health</div>
  <div className={style.logoBottomLine}>Client Portal</div>
</div>
</div>
<div className={style.errorRow}>
<div className={style.errorBox}>
  <div className={style.errorHeader}>OOPS</div>
  <div className={style.slimheader}>There are no policies associated<br/>with this account.</div>
  <div className={style.slimheader}>For more information<br/>please contact support:<br/>888-352-5124</div>
  <a href="/login" className={style.wrongAccount}>Wrong account?<br/> Click Here to Login With a<br/>Different Account</a>
</div>
</div>
   </div>;
};
export default NoPolicies