import authService from "./auth.service";
import { jwtDecode } from "jwt-decode";

export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.access_token) {
      const token = jwtDecode(user.access_token);
      if (Date.now() >= token.exp * 1000) {
      return authService.refresh({ 'Authorization': 'Bearer ' + user.refresh_token }).then((response) => {
          const newUser = JSON.parse(localStorage.getItem('user'));
          if (response) {
            newUser.access_token = response.access_token;
            localStorage.setItem("user", JSON.stringify(newUser));
          }
          return Promise.resolve({ 'Authorization': 'Bearer ' + newUser.access_token });
        });
      }
      else
      {
        return Promise.resolve({ 'Authorization': 'Bearer ' + user.access_token });
      }
    } else {
      console.log('user dead');
      window.location.href="/login";
      return Promise.reject('Error with user access, please re-log in.');
    }
  };