import React from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import style from './HomeOffice.module.css'

const blueIconColor = '#44abdd'
const redIconColor = '#d32f2f'

const PolicySearchInput = ({ handleChange, handleKeyPress, formInfo, submitPolicySearch, policySearchError }) => {
    //policyNotFound = true
    const error = (policySearchError) ? "Policy # does not Exist" : ""

    return (
        <TextField
            id="policyNumber"
            name="policyNumber"
            variant="outlined"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            value={formInfo.policyNumber}
            aria-label="Enter Policy #"
            error={error}
            helperText={error}
            className={style.policySearchField}
            InputProps={{
                endAdornment: (
                    <div className={style.policySearchAdornment}>
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Submit Policy #"
                                onClick={submitPolicySearch}
                            >
                                <SearchIcon style={{ color: error ? redIconColor : blueIconColor, fontSize: '1.5em', }} />
                            </IconButton>
                        </InputAdornment>
                    </div>
                ),
            }}
        />
    )
}

export default PolicySearchInput