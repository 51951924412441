import React from 'react';
import { Grid } from '@mui/material';
import LeftNav from '../components/LeftNav/LeftNav';
import PaymentCenterDetails from '../components/PaymentCenterDetails/PaymentCenterDetails'

const PaymentCenter = () => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={2}>
        <LeftNav activePage="paymentcenter" />
      </Grid>
      <Grid item sm={10}>
        <PaymentCenterDetails />
      </Grid>
    </Grid>);
};
export default PaymentCenter