import React from 'react';
import style from './PendingClaimsDetails.module.css'
import PolicyInfoSmall from '../PolicyInfoSmall/PolicyInfoSmall';
import ad from './ad.jpg';
import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const rows = [
    {id: 1, claimant:'OMNI A KOSKINEN', recieved: '09/09/20', dateOfService: '07/23/19', claimNumber: 123453234 },
    {id: 2, claimant:'OMNI A KOSKINEN', recieved: '09/09/20', dateOfService: '07/23/19', claimNumber: 123453234 },
    {id: 3, claimant:'OMNI A KOSKINEN', recieved: '09/09/20', dateOfService: '07/23/19', claimNumber: 123453234 },
    {id: 4, claimant:'OMNI A KOSKINEN', recieved: '09/09/20', dateOfService: '07/23/19', claimNumber: 123453234 },
    {id: 5, claimant:'OMNI A KOSKINEN', recieved: '09/09/20', dateOfService: '07/23/19', claimNumber: 123453234 },
    {id: 6, claimant:'OMNI A KOSKINEN', recieved: '09/09/20', dateOfService: '07/23/19', claimNumber: 123453234 },
  ];

const PendingClaimsDetails  = () => {
  return <div className={style.pageContainer}>
  <div className={style.ad}>
      <img className={style.adImg} alt="Enroll in direct deposit today" src={ad}/>
  </div>
  {/* Top Row Grid */}
  <Grid container spacing={3}>
  <Grid item xs={12} sm={9}>
      <div className={style.welcomeHolder}>
      <div className={style.welcome}><span className={style.Hello}>Pending</span> Claims</div>
      </div>
      



    </Grid>
   
    <Grid item xs={12} sm={3}>
    <Button  fullWidth c variant="contained" color="primary">Download My Policy</Button>
    </Grid>
  </Grid>

  <br/><br/>
  <Grid container spacing={3}>
      <Grid item xs={12} sm={9}>
          <Grid container spacing={3}>
          <div className={style.claimsTable} >
          <h3>Pending Claims</h3>
          
          <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Claimant</TableCell>
            <TableCell align="right">Received</TableCell>
            <TableCell align="right">Date of Service</TableCell>
            <TableCell align="right">Claim #</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.claimant}
              </TableCell>
              <TableCell align="right">{row.recieved}</TableCell>
              <TableCell align="right">{row.dateOfService}</TableCell>
              <TableCell align="right">{row.claimNumber}</TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <div>
    <br></br>
    <h3>Confidential Claims Summary*</h3>
    <div className={style.claimsSummary}>For information regarding claims received by us more than three months ago, please contact Benefits at 1-800-352-5130</div>
</div>

      </div>

          </Grid>
      </Grid>     
     {/* Policy Info Side Bar */}

      <Grid item xs={12} sm={3}>
<PolicyInfoSmall/>
      </Grid>
  </Grid>


</div>;
};
export default PendingClaimsDetails