import React from 'react';
import style from './PaymentCenterDetails.module.css'
import { Button, Grid } from '@mui/material';
import PolicyInfoSmall from '../PolicyInfoSmall/PolicyInfoSmall';
import ad from './ad.jpg';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const PaymentCenterDetails  = () => {
  return  <div className={style.pageContainer}>
  <div className={style.ad}>
      <img className={style.adImg} alt="Enroll in direct deposit today" src={ad}/>
  </div>
  {/* Top Row Grid */}
  <Grid container spacing={3}>
  <Grid item xs={12} sm={6}>
      <div className={style.welcomeHolder}>
      <div className={style.welcome}><span className={style.Hello}>Payment</span> Center</div>
      </div>
      



    </Grid>
    <Grid item xs={12} sm={3}>
    <Button  fullWidth startIcon={<CloudDownloadIcon />}   color="secondary">Download Recurring Payment Form</Button>
    </Grid>
    <Grid item xs={12} sm={3}>
    <Button  fullWidth c variant="contained" color="primary">Download My Policy</Button>
    </Grid>
  </Grid>

  <br/><br/>
  <Grid container spacing={3}>
      <Grid item xs={12} sm={9}>
          <Grid container spacing={3}>
          <div className={style.epaymentsHolder}>
          <h3>ePayments</h3>
          <hr/>
          <p>
          Schedule a recurring payment using your checking or savings account. Enroll today by filling out the recurring payment form and uploading it to our client portal, calling 1-800-352-5150. </p><p> If you would like to make a payment online please schedule an online payment.
          </p>

<br/>
<br/>
          <h2>You currently have no payments scheduled</h2>
          <Button variant="contained" color="primary">Schedule Online Payment</Button>
      </div>

          </Grid>
      </Grid>     
     {/* Policy Info Side Bar */}

      <Grid item xs={12} sm={3}>
<PolicyInfoSmall/>
      </Grid>
  </Grid>


</div>;
};
export default PaymentCenterDetails