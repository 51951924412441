import React from 'react';
import style from './ClaimsHistoryDetails.module.css'
import PolicyInfoSmall from '../PolicyInfoSmall/PolicyInfoSmall';
import ad from '../PendingClaimsDetails/ad.jpg';
import { Button, Grid, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table }  from '@mui/material';

const resolvedRows = [
    {id: 1, claimant: 'OMNI A KOSKINEN', cn: 5034239230, rd: '12/25/20', pd: '12/31/20', dos: '06/4/20'},
    {id: 2, claimant: 'OMNI A KOSKINEN', cn: 5034239230, rd: '12/25/20', pd: '12/31/20', dos: '06/4/20'},
    {id: 3, claimant: 'OMNI A KOSKINEN', cn: 5034239230, rd: '12/25/20', pd: '12/31/20', dos: '06/4/20'},
    {id: 4, claimant: 'OMNI A KOSKINEN', cn: 5034239230, rd: '12/25/20', pd: '12/31/20', dos: '06/4/20'},
    {id: 5, claimant: 'OMNI A KOSKINEN', cn: 5034239230, rd: '12/25/20', pd: '12/31/20', dos: '06/4/20'},
    {id: 6, claimant: 'OMNI A KOSKINEN', cn: 5034239230, rd: '12/25/20', pd: '12/31/20', dos: '06/4/20'}
]

const ClaimsHistoryDetails  = () => {
  return <div className={style.pageContainer}>
  <div className={style.ad}>
      <img className={style.adImg} alt="Enroll in direct deposit today" src={ad}/>
  </div>
  {/* Top Row Grid */}
  <Grid container spacing={3}>
  <Grid item xs={12} sm={9}>
      <div className={style.welcomeHolder}>
      <div className={style.welcome}><span className={style.Hello}>Pending</span> Claims</div>
      </div>
      



    </Grid>
   
    <Grid item xs={12} sm={3}>
    <Button  fullWidth c variant="contained" color="primary">Download My Policy</Button>
    </Grid>
  </Grid>

  <br/><br/>
  <Grid container spacing={3}>
      <Grid item xs={12} sm={9}>
          <Grid container spacing={3}>
          <div className={style.claimsTable} >
      <h3 >Resolved Claims for Policy #3864124</h3>
<TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Claimant</TableCell>
            <TableCell align="right">Claim #</TableCell>
            <TableCell align="right">Received Date</TableCell>
            <TableCell align="right">Paid Date</TableCell>
            <TableCell align="right">Date of Service </TableCell>
            <TableCell align="right">Details </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resolvedRows.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.claimant}
              </TableCell>
              <TableCell align="right">{row.cn}</TableCell>
              <TableCell align="right">{row.rd}</TableCell>
              <TableCell align="right">{row.pd}</TableCell>
              <TableCell align="right">{row.dos}</TableCell>
              <TableCell align="right"><a href="/">DOWNLOAD</a></TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <div>
    <br></br>
    <h3>Confidential Claims Summary*</h3>
    <div className={style.claimsSummary}>For information regarding claims received by us more than three months ago, please contact Benefits at 1-800-352-5130</div>
</div>

      </div>

          </Grid>
      </Grid>     
     {/* Policy Info Side Bar */}

      <Grid item xs={12} sm={3}>
<PolicyInfoSmall/>
      </Grid>
  </Grid>


</div>;;
};
export default ClaimsHistoryDetails