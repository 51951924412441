import React, {useState} from 'react';
import style from './ResetPassword.module.css'
import logo from './logo.png';
import {useLocation} from "react-router-dom";
import { Button } from '@mui/material';
import AuthService from "../../services/auth.service";
import UtilityService from "../../services/util.service";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword  = () => {
  let query = useQuery();
    const [inputs, setInputs] = useState({
      password: '',
      confirmpassword: ''
      });
      const [isSubmitting, setIsSubmitting] = useState(false);
      const [reset, setReset] = useState(false);
      
            const handleChange = (event) => {
        event.persist();
        setInputs(values => ({ ...values, [event.target.name]: event.target.value }));
      };
    
      const handleSubmit = (event) => {
        if (event) event.preventDefault();
        AuthService.resetPassword({password: inputs.password, token: query.get("token") }).then(
          () => {     
setReset(true);
window.location.href="/login";
          },
          (error) => {
           alert('Something went wrong, please try again!');
          }
        );
        setIsSubmitting(true);
      };



  return <div className={style.body}>
  <div className={style.topRow}>
<div className={style.logoHolder}>
<img className={style.logo} alt="Silac Insurance Logo" src={logo}/>
</div>
<div className={style.portalLogo}>
<div className={style.logoTopLine}>Life & Health</div>
<div className={style.logoBottomLine}>Client Portal</div>
</div>
</div>
<div className={style.signinRow}>
<div className={style.signInBox}>
<div className={style.signinHeader}>Password Reset</div>
<p className={style.resetDetails}>
Please create a new password to Login to your account
</p>
<form className={style.signinForm} onSubmit={handleSubmit}>
  <input helperText="Incorrect entry." type="password" value={inputs.password || ''} onChange={handleChange} placeholder="Enter New Password" name="password" className={style.userName}/> 
  {inputs.password !== "" ?  <div className={style.helpText} >{UtilityService.validatePassword(inputs.password)}</div> : ""}<br/>
  <input type="password" value={inputs.confirmpassword || ''} onChange={handleChange} placeholder="Confirm New Password" name="confirmpassword" className={style.userName}/> 
  {inputs.confirmpassword !== inputs.password && inputs.confirmpassword !== "" ?  <div className={style.helpText}>Passwords must match</div> : ""}<br/>

 
  <Button disabled={isSubmitting || inputs.password === '' || UtilityService.validatePassword(inputs.password).length > 0 || inputs.confirmpassword !== inputs.password} type="submit" fullWidth className={style.loginButton} variant="contained" color="primary">Set New Password</Button><br/>
 
  {reset ? <div className={style.checkEmail}> Your password has been reset. </div> : ""}

  <div className={style.forgotPassword}>
  
    <a href="/login" className={style.backToLogin}>Back</a>
  </div>
</form>

</div>
</div>



 </div>;
};
export default ResetPassword