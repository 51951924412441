import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || window.API_URL;
const HOME_OFFICE_API_URL = process.env.REACT_APP_HOME_LOGIN_API_URL || window.HOME_LOGIN_API_URL;


const login = (username, password) => {
  return axios
    .post(API_URL + "login", {
      "user": username,
      "password": password,
    }, {
      headers: {
        "Content-Type": "application/json"
      }})
    .then((response) => {
      if (response.data.access_token) {
      localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const home_office_login = (username, password) => {
  return axios
    .post(HOME_OFFICE_API_URL + "home_office_login", {
      "user": username,
      "password": password,
    }, {
      headers: {
        "Content-Type": "application/json"
      }})
    .then((response) => {
      if (response.data.access_token) {
      localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};


const create = (ssn_number, policy_number, email, password, security_question, security_answer) => {
  return axios
    .post(API_URL + "account/create_account", {
      "ssn": ssn_number,
      "policy_number": policy_number,
      "email": email,
      "password":password,
      "security_question" : security_question,
      "security_answer" :  security_answer
    }, {
      headers: {
        "Content-Type": "application/json"
      }})
    .then((response) => {
      return response.data;
    });
};



const refresh = (header) =>  {
  return axios
  .post(API_URL + "refresh-access-token", {}, {
    headers: header
    })
  .then((response) => {
    return response.data;
  });
}

const resetPassword = (data) => {
  return axios
  .post(API_URL + "reset_password_from_email", {password: data.password}, {
    headers: { 'Authorization': 'Bearer ' + data.token }
    })
  .then((response) => {
    return response.data;
  });
}

const resetPasswordRequest = (data) => {
  return axios
  .post(API_URL + "send_password_reset_email", {email: data.email})
  .then((response) => {
    return response.data;
  });
}



const logout = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  localStorage.removeItem("user");
  if (user && user.access_token) {
    axios.delete(API_URL + "refresh-revoke", {
      headers: { 'Authorization': 'Bearer ' + user.refresh_token }
    }).then(() => {}, (error) => {
      if(!error || !error.response || error.response.status !== 422){
        console.log(error)
      }
    });

    axios.delete(API_URL + "access-revoke", {
      headers: { 'Authorization': 'Bearer ' + user.access_token }
    }).then(() => {}, (error) => {
      if(!error || !error.response || error.response.status !== 422){
        console.log(error)
      }
    });
  }
};




const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};


const getJwtValueByKey = (requestedKey, tokenType = 'access_token') => {
  tokenType = (tokenType !== 'access_token') ? 'refresh_token' : tokenType
  return parseJwt(getCurrentUser()?.[tokenType])?.[requestedKey]
}


const parseJwt = (token) => {
  let result = {}

  if(typeof token === "string") {
    const base64Url = token.split('.')[1]
    if(base64Url) {
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      result = JSON.parse(window.atob(base64))
    }
  }
  
  return result
}


const auth = {
  login,
  home_office_login,
  create,
  logout,
  getCurrentUser,
  resetPasswordRequest,
  resetPassword,
  refresh,
  getJwtValueByKey,
  parseJwt
};
export default auth;