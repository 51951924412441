import numeral from 'numeral'

const getPrettyDate = () => {
    const date = new Date();
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    return days[date.getDay()] + ' ' + months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
};

const simpleDate = (date, defaultValue = "") => {
    if(date != null){
        let newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        return (newDate.getMonth() + 1) + "/" + (newDate.getDate()) + "/" + newDate.getFullYear();
    }
    else{
        return defaultValue;
    }
}

const getYear = (date) => {
    if(date != null){
      const newDate = new Date(date);
      return newDate.getFullYear();
    }
    else{
        return "";
    }
}


let base64ToArrayBuffer = (data) =>  {
  var binaryString = window.atob(data);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
  }
  return bytes;
};


let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };
  
    return null
  };
  
  let is_socialSecurity_Number = (str) =>
  {
   let regexp = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
    
          if (regexp.test(str))
            {
              return true;
            }
          else
            {
              return false;
            }
  }

  let formatDollar = (value) => {
  	let v = (!value) ? '0' : value.toString().replace(/[^0-9.]/g,'');
    return (!v || isNaN(v) || v === '0') ? '0' : numeral(parseFloat(v).toFixed(2)).format( '0,0.00' )
  }


  let validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const getHeaderMessage = () => {
  const mountainTime = new Date().toLocaleString("en-US", { timeZone: "MST" });
  let message = "";
  if (
    mountainTime >
      new Date(2021, 10, 17).toLocaleString("en-US", { timeZone: "MST" }) &&
    mountainTime <
      new Date(2021, 10, 19).toLocaleString("en-US", { timeZone: "MST" })
  ) {
    message = (
      <div>
        <p>Upcoming Adjusted Phone Line Hours</p>
        <p>
          We will be undergoing a system upgrade and our phone lines will be
          shut down on Friday, 11/19, from 6:00 AM Mountain Time to 9:00 AM
          Mountain Time.
        </p>
      </div>
    );
  } else if (
    mountainTime >
      new Date(2021, 10, 19).toLocaleString("en-US", { timeZone: "MST" }) &&
    mountainTime <=
      new Date(2021, 10, 19, 9).toLocaleString("en-US", { timeZone: "MST" })
  ) {
    message = (
      <div>
        <p>Adjusted Phone Line Hours.</p>
        <p>
          We are undergoing a system upgrade and our phone lines will be shut
          down today from 6:00 AM Mountain Time to 9:00 AM Mountain Time.
        </p>
      </div>
    );
  }
  return message;
};

const validatePassword = (password) => {
  var errors = []
  if (password.length < 8) {
    errors.push(<div>Your password must contain at least 8 characters.</div>)
  }
  if (!/\d/.test(password)) {
    errors.push(<div>Your password must contain at least 1 number.</div>)
  }
  if (!/[A-Z]/.test(password)) {
    errors.push(<div>Your password must contain at least 1 upper case character.</div>)
  }
  if (!/[a-z]/.test(password)) {
    errors.push(<div>Your password must contain at least 1 lower case character.</div>)
  }
  if (!/\W/.test(password)) {
    errors.push(<div>Your password must contain at least 1 special character.</div>)
  }
  return errors
}

  const util = {
    getPrettyDate,
    validateEmail,
    base64ToArrayBuffer,
    is_socialSecurity_Number,
    formatPhoneNumber,
    simpleDate,
    formatDollar,
    getYear,
    getHeaderMessage,
    validatePassword
  };
  export default util;

