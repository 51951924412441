import React from 'react';
import style from './AgentInformationDetails.module.css'
import { Button, Grid } from '@mui/material';
import PolicyInfoSmall from '../PolicyInfoSmall/PolicyInfoSmall';
import ad from '../PaymentCenterDetails/ad.jpg';


const AgentInformationDetails  = () => {
  return <div className={style.pageContainer}>
  <div className={style.ad}>
      <img className={style.adImg} alt="Enroll in direct deposit today" src={ad}/>
  </div>
  {/* Top Row Grid */}
  <Grid container spacing={3}>
  <Grid item xs={12} sm={9}>
      <div className={style.welcomeHolder}>
      <div className={style.welcome}><span className={style.Hello}>Agent</span> Information</div>
      </div>
   </Grid>
    <Grid item xs={12} sm={3}>
    <Button  fullWidth c variant="contained" color="primary">Download My Policy</Button>
    </Grid>
  </Grid>

  <br/><br/>
  <Grid container spacing={3}>
      <Grid item xs={12} sm={9}>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
              <div className={style.card}>
                  <h3 className={style.cardTitle}>Agent of Record</h3>
                  <h2 className={style.name}>Christina Morello</h2>
                  <div className={style.address}>1234 Anytown Way</div>
                  <div className={style.phone}>(123) 456-7890</div>
                  <div className={style.email}>agent@agency.com</div>
              </div>
          </Grid>
          <Grid item xs={12} sm={6}>
          <div className={style.card}>
                  <h3 className={style.cardTitle}>Upline Agent</h3>
                  <h2 className={style.name}>Bobby Stanfoloposis</h2>
                  <div className={style.address}>1234 Anytown Way</div>
                  <div className={style.phone}>(123) 456-7890</div>
                  <div className={style.email}>agent@agency.com</div>
              </div>
          </Grid>
          </Grid>
      </Grid>     
     {/* Policy Info Side Bar */}

      <Grid item xs={12} sm={3}>
<PolicyInfoSmall/>
      </Grid>
  </Grid>


</div>;
};
export default AgentInformationDetails