
import './App.css';
import { Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import HomeOfficeLogin from "./components/HomeOfficeLogin/Login";
import NoPolicies from "./components/NoPolicies/NoPolicies";
import Home from "./pages/Home";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PaymentCenter from './pages/PaymentCenter';
import PendingClaims from './pages/PendingClaims';
import ClaimsHistory from './pages/ClaimsHistory';
import AgentInformation from './pages/AgentInformation';
import PrivateRoute from './components/auth/PrivateRoute';
import ForgotPassword from './components/ForgotPassword/ForgotPassword'
import AccountSettings from './pages/AccountSettings';
import ResetPassword from './components/ResetPassword/ResetPassword';
import CreateAccount from './components/CreateAccount/CreateAccount';
import Success from './components/SuccessPage/Success';

const theme = createTheme({
  palette: {
    action: {
      disabledBackground: '#44abdd9e',
      disabled: '#ffffff8a'
    },
    primary: {
      500: '#44ABDD',
      contrastText: "#fff"
    },
    secondary: {
      main: '#FF6F3C',
      contrastText: "#fff"
    }
  },
});

function App() {
  return (
    <div className="App">

      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/forgot" element={<ForgotPassword/>} />
          <Route path="/create" element={<CreateAccount/>} />
          <Route path="/success" element={<Success/>} />
          <Route path="/reset" element={<ResetPassword/>} />
          <Route path="/home-office-login" element={<HomeOfficeLogin/>} />

          <Route path="/" exact element={<PrivateRoute Component={Home} />} />
          <Route path="/home" exact element={<PrivateRoute Component={Home} />}/>
          <Route path="/paymentCenters" exact element={<PrivateRoute Component={PaymentCenter}/>}/>
          <Route path="/pendingClaims" exact element={<PrivateRoute Component={PendingClaims} />}/>
          <Route path="/claimhistory" exact element={<PrivateRoute Component={ClaimsHistory} />}/>
          <Route path="/agentinformation" exact element={<PrivateRoute Component={AgentInformation}/>}/>
          <Route path="/account" exact element={<PrivateRoute Component={AccountSettings} />}/>

          <Route path="/login" element={<Login/>} />
          <Route path="/nopolicies" element={<NoPolicies/>} />

        </Routes>
      </ThemeProvider>

    </div>

  );
}

export default App;
