import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL || window.API_URL;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // Handle 401 error here
      console.log("Unauthorized login, redirect to login page.");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

const getUser = async () => {
  const header = await authHeader();
  return axios.get(API_URL + "user", { headers: header });
};

const getPolicyInfo = async () => {
  const header = await authHeader();
  return axios.get(API_URL + "policies", { headers: header });
};

const getClaimPDF = async (currentPolicy, claimNumber) => {
  const header = await authHeader();
  return axios.get(API_URL + "policies/" + currentPolicy + "/resolved_claims/" + claimNumber + "/eob_pdf", { headers: header, responseType: 'blob' });
}

const getPeronalInfo = async () => {
  const header = await authHeader();
  return axios.get(API_URL + "account/personal_information", { headers: header });
}

const getPeronalInfoByPolicy = async (policyNumber) => {
  const header = await authHeader();
  return axios.get(API_URL + "account/personal_information/" + policyNumber, { headers: header });
}



const updatePersonalInfo = async (info) => {
  const header = await authHeader();
  return axios
  .patch(API_URL + "account/personal_information", info, {  headers: 
    header
})
  .then((response) => {
    return response.data;
  });
}

const getSecurityQuestion = async () => {
  const header = await authHeader();
  return axios.get(API_URL + "account/security_question", { headers: header });
}

const updateSecurityQuestion = async (info) => {
  const header = await authHeader();
  return axios
  .patch(API_URL + "account/security_question", info, {  headers: 
    header
})
  .then((response) => {
    return response.data;
  });
}

const changePassword = async (old_password, new_password) => {
  const header = await authHeader();
  return axios
    .post(API_URL + "change_password", { old_password: old_password, new_password: new_password }, { headers: header })
    .then((response) => {
      return response.data;
    });
}

const verifyPolicy = async (policyNumber) => {
  const header = await authHeader();
  return axios.get(API_URL + "policies/" + policyNumber, { headers: header });
}

const getAllPolicyInfo = async (policyNumber) => {
  const header = await authHeader();
    let getPolicyInformation = API_URL + "policies/" + policyNumber;
    let getResolvedClaims = API_URL + "policies/" + policyNumber + "/resolved_claims";
    let getPendingClaims = API_URL + "policies/" + policyNumber + "/pending_claims";
    let getUsedBenefits = API_URL + "policies/" + policyNumber + "/used_benefits";
    let getAvailableBenefits = API_URL + "policies/" + policyNumber + "/available_benefits";
    
    const requestOne = axios.get(getPolicyInformation, { headers: header });
    const requestTwo = axios.get(getResolvedClaims, { headers: header });
    const requestThree = axios.get(getPendingClaims, { headers: header });
    const requestFour = axios.get(getUsedBenefits, { headers: header });
    const requestFive = axios.get(getAvailableBenefits, { headers: header });
    return axios.all([requestOne, requestTwo, requestThree,requestFour, requestFive]).then(axios.spread((...responses) => {
      const data = [];
      data.push(responses[0]);
      data.push(responses[1]);
      data.push(responses[2]);
      data.push(responses[3]);
      data.push(responses[4]);
      return data;
    })).catch(errors => {
      return errors;
    }); 
}

const dataService = {
    getUser,
    getAllPolicyInfo,
    getPolicyInfo,
    getClaimPDF,
    getPeronalInfo,
    getPeronalInfoByPolicy,
    updatePersonalInfo,
    changePassword,
    getSecurityQuestion,
    updateSecurityQuestion,
    verifyPolicy
};

export default dataService;
