import React from 'react';
import { Grid } from '@mui/material';
import LeftNav from '../components/LeftNav/LeftNav';
import AgentInformationDetails from '../components/AgentInformation/AgentInformationDetails';

const AgentInformation = () => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={2}>
        <LeftNav activePage="agentinformation" />
      </Grid>
      <Grid item sm={10}>
        <AgentInformationDetails />
      </Grid>
    </Grid>);
};
export default AgentInformation