import style from './LeftNav.module.css'
import {Link, useNavigate} from "react-router-dom";
import AuthService from "../../services/auth.service";

import logo from "./logo.jpg";
const LeftNav  = ({activePage}) => {
    const navigate = useNavigate();
    const logOut = () => {
        const url = (AuthService.getJwtValueByKey('isHomeOffice')) ? '/home-office-login' : '/login'
        AuthService.logout();
        navigate(url);
      };
  return <div className={style.navHolder}>
      <div className={style.logoHolder}>
          <img src={logo} alt="Silac Insurance Company"/>
      </div>
      <ul className={style.navList}>
          <li className={ activePage === "policyinformation" ? style.active : null}><Link to="/home">Policy Information</Link></li>
          {/* <li className={ activePage === "paymentcenter" ? style.active : null}><Link to="/paymentcenter">Payment Center</Link></li>
          <li className={ activePage === "pendingclaims" ? style.active : null}><Link to="/pendingclaims">Pending Claims</Link></li>
          <li className={ activePage === "claimhistory" ? style.active : null}><Link to="/claimhistory">Claims History</Link></li>
          <li className={ activePage === "agentinformation" ? style.active : null}><Link to="/agentinformation">Agent Information</Link></li>
          <li className={ activePage === "messagecenter" ? style.active : null}><Link to="/messagecenter">Message Center</Link></li> */}
          <li className={ activePage === "account" ? style.active : null}><Link to="/account">Account Settings</Link></li>
          {/* <li className={ activePage === "forms" ? style.active : null}><Link to="/forms">Forms</Link></li> */}
          <li onClick={logOut}>Logout</li>
      </ul>
  </div>;
};
export default LeftNav